import ExtendSubscription from "@components/Subscriptions/ExtendSubscription";
import { lazy } from "react";
import {
  AiOutlineCar,
  AiOutlineCreditCard,
  AiOutlineFile,
  AiOutlineGift,
  AiOutlineHome,
  AiOutlineIdcard,
  AiOutlineShoppingCart,
  AiOutlineSync,
  AiOutlineWallet,
} from "react-icons/ai";
import ConfirmEmailChange from "../components/Auth/ConfirmEmailChange";
import AccountNotVerified from "@components/AccountNotVerified/AccountNotVerified";
import DigitealInfo from "@components/Digiteal/DigitealInfo";

// Auth Components
const LoginForm = lazy(() => import("@components/Auth/LoginForm"));
const ForgotPass = lazy(() => import("@components/Auth/ForgotPass"));
const SignUp = lazy(() => import("@components/Auth/SignUp"));
const AcccountActivate = lazy(() => import("@components/Auth/AccountActivate"));
const ChangePassword = lazy(() => import("@components/Auth/ChangePassword"));
const VoucherFulfillment = lazy(() =>
  import("@components/DiscountVoucher/VoucherFulfillment")
);

// CompleteAccount Components
const PersonalDetails = lazy(() =>
  import("@components/CompleteAccount/PersonalDetails")
);

// Dashboard Components
const Home = lazy(() => import("@components/Home/Home"));
const Subscriptions = lazy(() =>
  import("@components/Subscriptions/Subscription")
);
const GiftVoucher = lazy(() => import("@components/GiftVoucher/GiftVoucher"));
const Credit = lazy(() => import("@components/Credit/Credit"));
const LicensePlates = lazy(() =>
  import("@components/LicensePlate/LicensePlate")
);
const CleaningCards = lazy(() =>
  import("@components/CleaningCard/CleaningCard")
);
const Webshop = lazy(() => import("@components/Webshop/Webshop"));
const MyDetails = lazy(() => import("@components/MyDetails/MyDetails"));
const Invoice = lazy(() => import("@components/Invoice/Invoice"));
const Transactions = lazy(() =>
  import("@components/Transactions/Transactions")
);
const LoginDetails = lazy(() =>
  import("@components/LoginDetails/LoginDetails")
);

const AdyenPage = lazy(() => import("@components/Adyen/Page"));
const AdyenStatus = lazy(() => import("@components/Adyen/Status"));

const IngenicoPage = lazy(() => import("@components/Ingenico/Page"));
const IngenicoStatus = lazy(() => import("@components/Ingenico/Status"));

const MultiSafepayPage = lazy(() => import("@components/MultiSafepay/Page"));
const MultiSafepayStatus = lazy(() =>
  import("@components/MultiSafepay/Status")
);

const MandateStatus = lazy(() =>
  import("@components/MandateActivation/Status")
);

const ErrorPage404 = lazy(() => import("@components/Errors/404"));

const applicationRoutes = {
  auth: [
    {
      path: "/auth/login",
      exact: true,
      pageTitle: "main.pageTitle.auth.login",
      component: LoginForm,
    },
    {
      path: "/auth/forgot-password",
      exact: true,
      pageTitle: "main.pageTitle.auth.forgotPassword",
      component: ForgotPass,
    },
    {
      path: "/auth/sign-up",
      exact: true,
      pageTitle: "main.pageTitle.auth.signUp",
      component: SignUp,
    },
    {
      path: "/activate/:id",
      exact: true,
      pageTitle: "main.pageTitle.auth.accountActivate",
      component: AcccountActivate,
    },
    {
      path: "/username/:id",
      exact: true,
      pageTitle: "main.pageTitle.auth.confirmEmailChange",
      component: ConfirmEmailChange,
    },
    {
      path: "/password/:id",
      exact: true,
      pageTitle: "main.pageTitle.auth.changePassword",
      component: ChangePassword,
    },
    {
      path: "/discount-vouchers/:discountVoucherUuid/fulfill",
      exact: true,
      pageTitle: "main.pageTitle.auth.voucherFulfillment",
      component: VoucherFulfillment,
    },
  ],
  completeAccount: [
    {
      path: "/complete-personal-details",
      exact: true,
      pageTitle: "main.pageTitle.completeAccount.completePersonalDetails",
      component: PersonalDetails,
    },
  ],
  dashboard: [
    {
      path: "/",
      exact: true,
      component: Home,
      pageTitle: "main.pageTitle.dashboard.dashboard",
      sidebarName: "main.sidebar.dashboard",
    },
    {
      path: "/dashboard",
      exact: true,
      component: Home,
      visibleOnSidebar: true,
      pageTitle: "main.pageTitle.dashboard.dashboard",
      sidebarName: "main.sidebar.dashboard",
      sidebarIcon: <AiOutlineHome />,
    },
    {
      path: "/dashboard/credit",
      exact: true,
      component: Credit,
      visibleOnSidebar: true,
      pageTitle: "main.pageTitle.dashboard.credit",
      sidebarName: "main.sidebar.credit",
      sidebarIcon: <AiOutlineWallet />,
    },
    {
      path: "/dashboard/license-plate",
      exact: true,
      component: LicensePlates,
      visibleOnSidebar: true,
      pageTitle: "main.pageTitle.dashboard.licensePlate",
      sidebarName: "main.sidebar.licensePlates",
      sidebarIcon: <AiOutlineCar />,
      code: "ANPR",
    },
    {
      path: "/dashboard/clean-card",
      exact: true,
      component: CleaningCards,
      visibleOnSidebar: true,
      pageTitle: "main.pageTitle.dashboard.cleanCards",
      sidebarName: "main.sidebar.cleanCards",
      sidebarIcon: <AiOutlineCreditCard />,
      code: ["RFID", "BARCODE"],
    },
    {
      path: "/dashboard/webshop",
      exact: true,
      component: Webshop,
      visibleOnSidebar: true,
      pageTitle: "main.pageTitle.dashboard.webshop",
      sidebarName: "main.sidebar.webshop",
      sidebarIcon: <AiOutlineShoppingCart />,
    },
    {
      path: "/dashboard/subscriptions",
      exact: true,
      component: Subscriptions,
      visibleOnSidebar: true,
      pageTitle: "main.pageTitle.dashboard.subscriptions",
      sidebarName: "main.sidebar.subscriptions",
      sidebarIcon: <AiOutlineIdcard />,
    },
    {
      path: "/mandate-activation/success",
      exact: true,
      component: MandateStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.subscriptions",
      sidebarName: "main.sidebar.subscriptions",
    },
    {
      path: "/mandate-activation/cancelled",
      exact: true,
      component: MandateStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.subscriptions",
      sidebarName: "main.sidebar.subscriptions",
    },
    {
      path: "/mandate-activation/error",
      exact: true,
      component: MandateStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.subscriptions",
      sidebarName: "main.sidebar.subscriptions",
    },
    {
      path: "/mandate-activation/failure",
      exact: true,
      component: MandateStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.subscriptions",
      sidebarName: "main.sidebar.subscriptions",
    },
    {
      path: "/dashboard/extend-subscription",
      exact: true,
      component: ExtendSubscription,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.subscriptions",
      sidebarName: "main.sidebar.subscriptions",
    },
    {
      path: "/dashboard/gift-voucher",
      exact: true,
      component: GiftVoucher,
      visibleOnSidebar: true,
      pageTitle: "main.pageTitle.dashboard.giftVoucher",
      sidebarName: "main.sidebar.giftVoucher",
      sidebarIcon: <AiOutlineGift />,
      code: "GIFT",
    },
    {
      path: "/dashboard/transactions",
      exact: true,
      component: Transactions,
      visibleOnSidebar: true,
      pageTitle: "main.pageTitle.dashboard.transactions",
      sidebarName: "main.sidebar.transactions",
      sidebarIcon: <AiOutlineSync />,
    },
    {
      path: "/dashboard/invoice",
      exact: true,
      component: Invoice,
      visibleOnSidebar: true,
      pageTitle: "main.pageTitle.dashboard.invoice",
      sidebarName: "main.sidebar.invoice",
      sidebarIcon: <AiOutlineFile />,
    },
    {
      path: "/my-details/:active_tab?",
      pathNames: [
        "personalInformation",
        "invoiceDetails",
        "settingDetails",
        "preferredLanguage",
        "locationsDetails",
        "usernameDetails",
        "passwordDetails",
      ],
      exact: true,
      component: MyDetails,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.myDetails",
      sidebarName: "main.sidebar.myDetails",
    },
    {
      path: "/my-login-details",
      exact: true,
      component: LoginDetails,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.myLoginDetails",
      sidebarName: "main.sidebar.myLoginDetails",
    },
    {
      path: "/dashboard/adyen/checkout",
      exact: true,
      component: AdyenPage,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.adyen",
      sidebarName: "main.sidebar.adyen",
    },
    {
      path: "/adyen/success",
      exact: true,
      component: AdyenStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.adyen.success",
      sidebarName: "main.sidebar.adyen.success",
    },
    {
      path: "/adyen/failed",
      exact: true,
      component: AdyenStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.adyen.failed",
      sidebarName: "main.sidebar.adyen.failed",
    },
    {
      path: "/adyen/pending",
      exact: true,
      component: AdyenStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.adyen.pending",
      sidebarName: "main.sidebar.adyen.pending",
    },
    {
      path: "/dashboard/ingenico/checkout",
      exact: true,
      component: IngenicoPage,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.ingenico",
      sidebarName: "main.sidebar.ingenico",
    },
    {
      path: "/ingenico/success",
      exact: true,
      component: IngenicoStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.ingenico.success",
      sidebarName: "main.sidebar.ingenico.success",
    },
    {
      path: "/ingenico/failed",
      exact: true,
      component: IngenicoStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.ingenico.failed",
      sidebarName: "main.sidebar.ingenico.failed",
    },
    {
      path: "/dashboard/multisafepay/checkout",
      exact: true,
      component: MultiSafepayPage,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.multisafepay",
      sidebarName: "main.sidebar.multisafepay",
    },
    {
      path: "/multisafepay/redirect",
      exact: true,
      component: MultiSafepayStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.multisafepay.notification",
      sidebarName: "main.sidebar.multisafepay.notification",
    },
    {
      path: "/multisafepay/notification",
      exact: true,
      component: MultiSafepayStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.multisafepay.notification",
      sidebarName: "main.sidebar.multisafepay.notification",
    },
    {
      path: "/multisafepay/cancelled",
      exact: true,
      component: MultiSafepayStatus,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.multisafepay.cancelled",
      sidebarName: "main.sidebar.multisafepay.cancelled",
    },
    {
      path: "/digiteal/info",
      exact: true,
      component: DigitealInfo,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.digiteal.info",
      sidebarName: "main.sidebar.digiteal.info",
    },
    {
      path: "/account-not-verified",
      component: AccountNotVerified,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.accountNotVerified",
      sidebarName: "main.sidebar.account-not-verified",
    },
    {
      path: "*",
      component: ErrorPage404,
      visibleOnSidebar: false,
      pageTitle: "main.pageTitle.dashboard.error",
      sidebarName: "main.sidebar.adyen.failed",
    },
  ],
};

export default applicationRoutes;
