import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";

const Title = ({ location, applicationRoutes }) => {
  const routes = applicationRoutes.dashboard;
  const { locale } = useIntl();
  const currentTitle = routes.find(
    (r) =>
      `/${locale}${r.path}` === location.pathname ||
      r?.pathNames?.includes(/[^/]*$/.exec(location.pathname)[0])
  );

  if (!currentTitle || !currentTitle.sidebarName) return null;

  return (
    <div className="title">
      <h3 className="title__header">
        <FormattedMessage id={currentTitle.sidebarName} />
      </h3>
    </div>
  );
};

export default withRouter(Title);
