import _ from "lodash";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CardText, CardTitle } from "reactstrap";
import LocalizedLink from "@components/I18n/LocalizedLink";
const PersonalData = ({ accountDetails, invoiceData, invoiceDataError }) => {
  return (
    <>
      <div className="mt-3 mb-3 badge badge-secondary badge-pill label-type-account">
        {invoiceDataError == null &&
        (!_.isEmpty(invoiceData.vatNumber) ||
          invoiceData.vatNumberRequested) ? (
          <FormattedMessage id="main.giftVoucher.businessAccount" />
        ) : (
          <FormattedMessage id="main.giftVoucher.privateAccount" />
        )}
      </div>
      <CardTitle className={"pt-0 pb-3"}>
        <FormattedMessage id="main.credit.personalData" />
      </CardTitle>
      <div className="name-wrapper">
        <CardText className="mb-2">
          {accountDetails.firstName} {accountDetails.lastName}
        </CardText>
      </div>
      <div className="link-wrapper">
        <LocalizedLink path="/my-details/personalInformation">
          <FormattedMessage id="main.credit.adjust" />
        </LocalizedLink>
      </div>
    </>
  );
};
export default PersonalData;
